import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

/** @jsxImportSource @emotion/react */
import { useRouter } from 'next/router';
import { redDotStyles, iconStyles } from './navbar-checkout.module.style';
import { useContext, useEffect, useState } from 'react';
import { AppConfigContext } from '../global-context-wrapper/global-context-wrapper';
import { IndievibeAmplitudeEventNames, sendAmplitudeData } from '@vc-workspace/utils-indievibe-amplitude';
/* eslint-disable-next-line */

export function NavbarCheckout(props) {
  var router = useRouter();

  var _useContext = useContext(AppConfigContext),
      itemAddedToCart = _useContext.itemAddedToCart,
      setItemAddedToCart = _useContext.setItemAddedToCart;

  var _useState = useState(false),
      showRedDot = _useState[0],
      toggleRedDot = _useState[1];

  function onCartClick() {
    sendAmplitudeData(IndievibeAmplitudeEventNames.GO_TO_CART, {
      source: 'Navbar',
      is_b2b_platform: true
    });
    router.push('/cart/init');
  }

  useEffect(function () {
    if (itemAddedToCart) {
      toggleRedDot(true);
    }
  }, [itemAddedToCart]);
  return _jsxs("div", {
    onClick: onCartClick,
    className: "inline-block relative cursor-pointer",
    children: [props.whiteTheme ? _jsx("img", {
      src: "/_mp-images/bag_white.svg",
      css: iconStyles
    }) : _jsx("img", {
      src: "/_mp-images/bag-outline.svg",
      css: iconStyles
    }), showRedDot ? _jsx("div", {
      id: "red_dot",
      css: redDotStyles
    }) : null]
  });
}
export default NavbarCheckout;