import _defineProperty from "/usr/src/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { LOCAL_WISHLIST_KEY, MARKETPLACE_CART_KEY, TTL_IN_MS, USER_VISIT_METADATA_KEY, VC_REFERRERS } from '@vc-workspace/utils-constants';
import { getKeyFromStorage, getB2BPrice, VCInternalNavigation, LocalStorage, setKeyInStorage } from '@vc-workspace/utils-helpers';
import { ShopPixelKeys } from '../marketplace-constants/marketplace-constants';
export function addToLocalCart(sia, ipq, shop) {
  var existingCartString = getKeyFromStorage(MARKETPLACE_CART_KEY);
  var existingCart = {
    items: [],
    customerDeliveryChargesDetails: []
  };

  if (existingCartString) {
    try {
      existingCart = JSON.parse(existingCartString);
    } catch (err) {
      existingCart = {
        items: [],
        customerDeliveryChargesDetails: []
      };
    }
  }

  var isNewCartEntry = true;
  var updatedCartItems = existingCart.items.map(function (item) {
    if (item.sia === sia._id && item.ipq === ipq._id) {
      item.quantity = item.quantity + 1;
      isNewCartEntry = false;
    }

    return item;
  });

  if (isNewCartEntry) {
    updatedCartItems.push({
      sia: sia._id,
      itemDetail: sia.itemDetail,
      variationUuid: ipq.variationUuid,
      shop: ipq.shop,
      ipq: ipq._id,
      currency: getB2BPrice(ipq.prices)[0].currency,
      quantity: 1,
      price: getB2BPrice(ipq.prices)[0].sellingPrice,
      status: ''
    });
  }

  var updatedDeliveryChargeDetails = existingCart.customerDeliveryChargesDetails;

  if (!updatedDeliveryChargeDetails.filter(function (item) {
    return item.shopId === shop._id;
  }).length) {
    updatedDeliveryChargeDetails.push({
      customerDeliveryCharges: 0,
      freeDeliveryThreshold: 0,
      logo: shop.logo[0],
      name: shop.name,
      publicUrl: shop.publicUrl,
      shopId: shop._id,
      shopSlug: shop.slug
    });
  }

  setKeyInStorage(MARKETPLACE_CART_KEY, JSON.stringify({
    items: updatedCartItems,
    customerDeliveryChargesDetails: updatedDeliveryChargeDetails
  }));
}
export function getCartFromLocal() {
  var _localCart$items$;

  var cartString = getKeyFromStorage(MARKETPLACE_CART_KEY);
  var localCart = {
    items: [],
    customerDeliveryChargesDetails: []
  };

  if (cartString) {
    try {
      localCart = JSON.parse(cartString);
    } catch (err) {
      localCart = {
        items: [],
        customerDeliveryChargesDetails: []
      };
    }
  }

  var totalAmount = 0;
  localCart.items.forEach(function (cartItem) {
    totalAmount += cartItem.quantity * cartItem.price;
  });
  var myCart = {
    amount: totalAmount,
    billingAddress: '',
    currency: (_localCart$items$ = localCart.items[0]) === null || _localCart$items$ === void 0 ? void 0 : _localCart$items$.currency,
    customerDeliveryCharges: calculateDeliveryCharges(localCart.items, localCart.customerDeliveryChargesDetails),
    customerDeliveryChargesDetails: localCart.customerDeliveryChargesDetails,
    items: localCart.items,
    orderType: 'MARKETPLACE',
    shippingAddress: '',
    totalPayableAmount: totalAmount
  };
  myCart.totalPayableAmount += myCart.customerDeliveryCharges;
  return myCart;
}
export function increaseLocalCartQuantity(currCart, sia, ipq, newQuantity) {
  var updatedCart = _objectSpread({}, currCart);

  updatedCart.items = updatedCart.items.map(function (item) {
    if (item.sia === sia && item.ipq === ipq) {
      item.quantity = newQuantity;
    }

    return item;
  });
  var totalAmount = 0;
  updatedCart.items.forEach(function (cartItem) {
    totalAmount += cartItem.quantity * cartItem.price;
  });
  updatedCart.amount = totalAmount;
  updatedCart.totalPayableAmount = totalAmount;
  updatedCart.customerDeliveryCharges = calculateDeliveryCharges(updatedCart.items, updatedCart.customerDeliveryChargesDetails);
  updatedCart.totalPayableAmount += updatedCart.customerDeliveryCharges;
  return updatedCart;
}
export function removeFromLocalCart(currCart, sia, ipq) {
  var updatedCart = _objectSpread({}, currCart);

  updatedCart.items = updatedCart.items.filter(function (item) {
    return item.sia !== sia && item.ipq !== ipq;
  });
  var totalAmount = 0;
  updatedCart.items.forEach(function (cartItem) {
    totalAmount += cartItem.quantity * cartItem.price;
  });
  updatedCart.amount = totalAmount;
  updatedCart.totalPayableAmount = totalAmount;
  updatedCart.customerDeliveryCharges = calculateDeliveryCharges(updatedCart.items, updatedCart.customerDeliveryChargesDetails);
  updatedCart.totalPayableAmount += updatedCart.customerDeliveryCharges;
  return updatedCart;
}
export function getLocalCartItems() {
  var localCartString = getKeyFromStorage(MARKETPLACE_CART_KEY);
  var localCart = {
    items: [],
    customerDeliveryChargesDetails: []
  };

  if (localCart) {
    try {
      localCart = JSON.parse(localCartString);
    } catch (err) {
      localCart = {
        items: [],
        customerDeliveryChargesDetails: []
      };
    }
  }

  return localCart.items;
}
export function getCartUpdateFormattedItems(myCartItems) {
  return myCartItems.map(function (item) {
    return {
      currency: item.currency,
      ipq: item.ipq,
      price: item.price,
      quantity: item.quantity,
      sia: item.sia
    };
  });
}
export function getDocumentReferrer(r) {
  if (r) {
    if (r.includes('google.com') || r.includes('google.co.in')) {
      return 'Google';
    } else if (r.includes('vibecity.com') || r.includes('staging.vibecity.club')) {
      return 'VC';
    } else if (r.includes('localhost')) {
      return undefined;
    } else {
      return new URL(r).hostname;
    }
  } else {
    return undefined;
  }
}
export function getUserVisitMetaStructure(queryParams, pageType) {
  var pageId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var page_rfr = arguments.length > 3 ? arguments[3] : undefined;
  var res;
  var now = new Date();
  res = _objectSpread(_objectSpread({}, res), {}, {
    cat: String(Date.now()),
    expiry: now.getTime() + TTL_IN_MS.TWO_DAYS
  });

  if (queryParams.utm_source) {
    res = _objectSpread(_objectSpread({}, res), {}, {
      us: queryParams.utm_source
    });
  }

  if (queryParams.utm_medium) {
    res = _objectSpread(_objectSpread({}, res), {}, {
      um: queryParams.utm_medium
    });
  }

  if (queryParams.utm_campaign) {
    res = _objectSpread(_objectSpread({}, res), {}, {
      uc: queryParams.utm_campaign
    });
  }

  if (pageType) {
    res = _objectSpread(_objectSpread({}, res), {}, {
      pg_typ: pageType
    });
  }

  if (page_rfr) {
    res = _objectSpread(_objectSpread({}, res), {}, {
      pg_rfr: getDocumentReferrer(page_rfr)
    });
  }

  if (pageId !== '') {
    res = _objectSpread(_objectSpread({}, res), {}, {
      pg_tid: pageId
    });
  }

  return res;
}
export function isThirdPartyReferrer(rfr) {
  if (rfr) {
    if (VC_REFERRERS.some(function (i) {
      return rfr.includes(i);
    })) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}
export function shouldAddUserVisitEntry(queryParams) {
  var _document;

  //* If internal navigation exists.
  if (VCInternalNavigation.isInternalNavigation()) {
    return false;
  } //* [ACCEPT] scenarios:
  //* Check if any utm_parameters exists:


  if (queryParams.utm_source || queryParams.utm_medium || queryParams.utm_campaign) {
    return true;
  } //* Is referer a third party website.


  if (isThirdPartyReferrer((_document = document) === null || _document === void 0 ? void 0 : _document.referrer)) {
    return true;
  } //* [REJECT] scenarios


  var now = new Date(); //* Check if most-recent entry is created in < 1 min:

  if (LocalStorage.getLatestCreatedAt(USER_VISIT_METADATA_KEY)) {
    if (Number(LocalStorage.getLatestCreatedAt(USER_VISIT_METADATA_KEY)) > now.getTime() - TTL_IN_MS.ONE_MIN) {
      return false;
    }
  }

  return true;
}
export function addItemToCart(myCart, cartItem) {
  var updatedCart = _objectSpread({}, myCart);

  var isNewCartEntry = true;
  updatedCart.items = updatedCart.items.map(function (item) {
    if (item.sia === cartItem.sia && item.ipq === cartItem.ipq) {
      item.quantity += cartItem.quantity;
      isNewCartEntry = false;
    }

    return item;
  });

  if (isNewCartEntry) {
    updatedCart.items.push(cartItem);
  }

  return updatedCart;
}
export function mergeLocalCartItems(myCart, itemsForAddition) {
  var updatedCart = _objectSpread({}, myCart);

  itemsForAddition.forEach(function (item) {
    updatedCart = addItemToCart(updatedCart, item);
  });
  return updatedCart;
}
export function filterShopsWithoutDeliveryCharges(myCart) {
  var updatedCart = _objectSpread({}, myCart);

  if (!updatedCart.items) {
    return updatedCart;
  }

  var shopWiseCartPrice = {};
  updatedCart.items.forEach(function (item) {
    if (!shopWiseCartPrice[item.shop]) {
      shopWiseCartPrice[item.shop] = 0;
    }

    shopWiseCartPrice[item.shop] += item.price * item.quantity;
  });
  updatedCart.customerDeliveryChargesDetails = updatedCart.customerDeliveryChargesDetails.filter(function (item) {
    return item.freeDeliveryThreshold > shopWiseCartPrice[item.shopId];
  });
  return updatedCart;
}
export function calculateDeliveryCharges(items, customerDeliveryChargesDetails) {
  var shopWiseCartPrice = {};
  items.forEach(function (item) {
    if (!shopWiseCartPrice[item.shop]) {
      shopWiseCartPrice[item.shop] = 0;
    }

    shopWiseCartPrice[item.shop] += item.price * item.quantity;
  });
  var applicableDeliveryCharge = customerDeliveryChargesDetails.filter(function (item) {
    return item.freeDeliveryThreshold > shopWiseCartPrice[item.shopId];
  });
  var deliveryCharge = 0;
  applicableDeliveryCharge.forEach(function (item) {
    deliveryCharge += item.customerDeliveryCharges;
  });
  return deliveryCharge;
}
export function addSIAToLocalWishlist(sia) {
  var existingWiishlistString = getKeyFromStorage(LOCAL_WISHLIST_KEY);
  var finalWishlist = {
    sias: []
  };

  if (existingWiishlistString) {
    try {
      finalWishlist = JSON.parse(existingWiishlistString);
    } catch (err) {
      finalWishlist = {
        sias: []
      };
    }
  }

  var isNewEntry = !finalWishlist.sias.filter(function (item) {
    return item._id === sia._id;
  }).length;

  if (isNewEntry) {
    finalWishlist.sias.push(sia);
  }

  setKeyInStorage(LOCAL_WISHLIST_KEY, JSON.stringify(finalWishlist));
}
export function getLocalWishlist() {
  var localWishlistString = getKeyFromStorage(LOCAL_WISHLIST_KEY);
  var localWishlist = {
    sias: []
  };

  if (localWishlistString) {
    try {
      localWishlist = JSON.parse(localWishlistString);
    } catch (err) {
      localWishlist = {
        sias: []
      };
    }
  }

  return localWishlist;
}
export function getPixelIdsForInitialisation(shopIds) {
  var pixelsToBeInitialised = [process.env.NEXT_PUBLIC_FB_PIXEL_KEY];

  if (shopIds && shopIds.length) {
    shopIds.forEach(function (shopId) {
      var pixelCode = ShopPixelKeys[shopId];

      if (pixelCode) {
        pixelsToBeInitialised.push(pixelCode);
      }
    });
  }

  return pixelsToBeInitialised;
}
export var FooterConstants = {
  support_number: '+1 669 202 2223',
  support_email: 'support@indievibe.co',
  address: 'Sector 7, HSR Layout, Bengaluru, India 560102',
  about_us_url: '/about',
  sell_on_vibecity_url: '/maker',
  privacy_policy_url: '/privacy-policy',
  terms_conditions_url: '/terms-conditions',
  return_policy_url: '/return-shipping-policy',
  instagram_url: 'https://www.instagram.com/indievibe.co',
  youtube_url: '',
  facebook_url: 'https://www.facebook.com/indievibe.co',
  twitter_url: '',
  faq_url: '/faq'
};