import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

/** @jsxImportSource @emotion/react */
import { useContext, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { AppConfigContext } from '../global-context-wrapper/global-context-wrapper';
import { Typography } from '@vc-workspace/utils-typography';
import { contentContainer, gridStyles, headerStyles, loginBanner, loginBtn } from './guest-wishlist.module.style';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { UtilsSeparator } from '@vc-workspace/utils-separator';
import { NavItemsLabel } from '../mobile-bottom-navbar/mobile-bottom-navbar';
import { useRouter } from 'next/router';
import { getLocalWishlist } from '../utilities/utilities';
import GuestWishlistSIACard from '../guest-wishlist-sia-card/guest-wishlist-sia-card';
import { IndievibeAmplitudeEventNames, sendAmplitudeData } from '@vc-workspace/utils-indievibe-amplitude';
/* eslint-disable-next-line */

export function GuestWishlist(props) {
  var router = useRouter();

  var _useContext = useContext(AppConfigContext),
      setSelectedTab = _useContext.setSelectedTab;

  var _useState = useState([]),
      localSIAS = _useState[0],
      setLocalSIAS = _useState[1];

  function onBackClick() {
    setSelectedTab(NavItemsLabel.HOME);
  }

  function onLoginClick() {
    sendAmplitudeData(IndievibeAmplitudeEventNames.LOGIN_INITIATED, {
      source: 'Wishlist',
      is_b2b_platform: true
    });
    window.location.href = '/profile/wishlist';
  }

  useEffect(function () {
    setLocalSIAS(getLocalWishlist().sias);
  }, []);
  return _jsx(_Fragment, {
    children: _jsx(Dialog, {
      open: true,
      onClose: function onClose() {
        /* Do nothing */
      },
      fullScreen: true,
      children: _jsxs("div", {
        className: "relative h-full",
        children: [_jsxs("div", {
          css: headerStyles,
          children: [_jsxs("div", {
            className: "flex items-center px-4 py-3",
            onClick: onBackClick,
            children: [_jsx(ChevronLeftIcon, {}), _jsx(Typography, {
              type: "SubHeading1",
              color: "prussian_blue",
              extra_bold: true,
              children: "Wishlist"
            })]
          }), localSIAS.length ? _jsx("div", {
            css: loginBanner,
            onClick: onLoginClick,
            children: _jsx(Typography, {
              type: "Body1",
              color: "white",
              semi_bold: true,
              children: "Login now to save your wishlist"
            })
          }) : _jsx(UtilsSeparator, {
            color: "white_lilac",
            spacingTop: "0px",
            spacingBottom: "12px",
            seperatorHeight: "4px"
          })]
        }), localSIAS.length ? _jsx("div", {
          className: "page-cushion-container pb-20",
          css: gridStyles,
          children: localSIAS.map(function (sia) {
            return _jsx(GuestWishlistSIACard, {
              sia: sia
            }, sia._id);
          })
        }) : _jsxs(_Fragment, {
          children: [_jsxs("div", {
            css: contentContainer,
            children: [_jsx("img", {
              src: "/_mp-images/wishlist_placeholder.svg",
              className: "inline-block"
            }), _jsx(Typography, {
              type: "Body1",
              color: "nero",
              className: "pt-8",
              semi_bold: true,
              children: "Products added to your Wishlist will be saved here."
            })]
          }), _jsx("button", {
            css: loginBtn,
            onClick: onBackClick,
            children: "Shop Now"
          })]
        })]
      })
    })
  });
}
export default GuestWishlist;