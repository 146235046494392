import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

/* eslint-disable react/jsx-no-target-blank */

/** @jsxImportSource @emotion/react */
import { getDisplayImageObj } from '@vc-workspace/utils-helpers';
import { UtilsSeparator } from '@vc-workspace/utils-separator';
import { Typography } from '@vc-workspace/utils-typography';
import { categoryImage, curatedCategoryList, curatedCategoryListItem } from './navbar-curated-category-list.module.style';
/* eslint-disable-next-line */

export function NavbarCuratedCategoryList(props) {
  return _jsxs(_Fragment, {
    children: [_jsx("div", {
      css: curatedCategoryList,
      className: "page-cushion-container--left",
      children: props.categories.map(function (curatedCategory) {
        var displayImage = getDisplayImageObj(curatedCategory.displayImages[0], '1');
        return _jsx("a", {
          href: "/p/".concat(curatedCategory.redirectionPage.slug, "/").concat(curatedCategory.redirectionPage.usId),
          target: props.context.isMobileView ? '_self' : '_blank',
          children: _jsxs("div", {
            css: curatedCategoryListItem,
            children: [_jsxs("picture", {
              children: [_jsx("source", {
                srcSet: displayImage.webP,
                type: "image/webp"
              }), _jsx("img", {
                src: displayImage.url,
                css: categoryImage
              })]
            }), _jsx(Typography, {
              type: "Body2",
              color: "nero",
              className: "text-center pt-2 whitespace-normal",
              children: curatedCategory.title
            })]
          })
        }, curatedCategory.redirectionPage.usId);
      })
    }), _jsx(UtilsSeparator, {
      color: "gainsboro",
      spacingTop: "0px",
      spacingBottom: "0px"
    })]
  });
}
export default NavbarCuratedCategoryList;