import { Autocomplete, Box, FormControl, FormGroup, InputLabel, TextField } from '@mui/material';
import { Colors, TailwindColors } from '@vc-workspace/utils-colors';
import { CountriesSchema } from '@vc-workspace/utils-schemas';
import { Typography } from '@vc-workspace/utils-typography';
import { useState } from 'react';

interface InternationalPhoneInputProps {
  countriesData: CountriesSchema[];
  phone: string;
  dialCode: string;
  setDialCode: (v: string) => void;
  setPhone: (v: string) => void;
}

export const InternationalPhoneInput = (props: InternationalPhoneInputProps) => {

  return (
    <div className="flex flex-col w-full">
      <div className="flex">
        <FormControl fullWidth sx={{ display: 'flex', flexDirection: 'row', }}>
          <FormControl>
            <Autocomplete
              id="country-select-demo"
              value={props.countriesData.filter(item => item.dialCode === props.dialCode)[0]}
              defaultValue={props.countriesData.filter(item => item.dialCode === props.dialCode)[0]}
              sx={{ width: '6rem', '& .MuiAutocomplete-input': {minWidth: '5rem !important'} }}
              options={props.countriesData}
              autoHighlight
              getOptionLabel={(option: CountriesSchema) => option.dialCode}
              renderOption={(props, option: CountriesSchema) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  {option.dialCode}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                  }}
                  placeholder="+1"
                  variant="standard"
                  sx={{
                    '& input': {
                      fontSize: '14px',
                      fontFamily: 'Manrope',
                    },
                    '& :before': {
                      borderBottom: `1px solid ${Colors.dark_grey}`
                    }
                  }}
                />
              )}
              onChange={(e, value: CountriesSchema | null) => value && props.setDialCode(value.dialCode)}
            />
          </FormControl>
          <TextField
            id="vc-b2b-login-phone-number"
            variant="standard"
            placeholder="Phone number"
            type="number"
            defaultValue={props.phone}
            fullWidth
            onChange={(e: any) => props.setPhone(e.target.value)}
            sx={{
              marginLeft: '2rem', paddingLeft: '1rem',
              '& input': {
                fontSize: '14px',
                fontFamily: 'Manrope',
              },
              '& :before': {
                borderBottom: `1px solid ${Colors.dark_grey}`
              }
            }}
          />
        </FormControl>
      </div>
    </div>
  );
};
