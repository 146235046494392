import { jsx as _jsx } from "@emotion/react/jsx-runtime";

/** @jsxImportSource @emotion/react */
import { useContext } from 'react';
import { AppConfigContext } from '../global-context-wrapper/global-context-wrapper';
import { containerStyles, whiteTheme } from './login-navbar-cta.module.style';
/* eslint-disable-next-line */

export function LoginNavbarCta(props) {
  var _useContext = useContext(AppConfigContext),
      setLoginState = _useContext.setLoginState;

  return _jsx("div", {
    css: [containerStyles, props.theme ? whiteTheme : null, true ? "" : ";label:LoginNavbarCta;", true ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi91c3Ivc3JjL2FwcC9hcHBzL21hcmtldHBsYWNlLWdsb2JhbC9jb21wb25lbnRzL2xvZ2luLW5hdmJhci1jdGEvbG9naW4tbmF2YmFyLWN0YS50c3giXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBa0JNIiwiZmlsZSI6Ii91c3Ivc3JjL2FwcC9hcHBzL21hcmtldHBsYWNlLWdsb2JhbC9jb21wb25lbnRzL2xvZ2luLW5hdmJhci1jdGEvbG9naW4tbmF2YmFyLWN0YS50c3giLCJzb3VyY2VzQ29udGVudCI6WyIvKiogQGpzeEltcG9ydFNvdXJjZSBAZW1vdGlvbi9yZWFjdCAqL1xuXG5pbXBvcnQgc3R5bGVkIGZyb20gJ0BlbW90aW9uL3N0eWxlZCc7XG5pbXBvcnQgeyBDb2xvcnMsIFRhaWx3aW5kQ29sb3JzIH0gZnJvbSAnQHZjLXdvcmtzcGFjZS91dGlscy1jb2xvcnMnO1xuaW1wb3J0IHsgVHlwb2dyYXBoeSB9IGZyb20gJ0B2Yy13b3Jrc3BhY2UvdXRpbHMtdHlwb2dyYXBoeSc7XG5pbXBvcnQgeyB1c2VDb250ZXh0IH0gZnJvbSAncmVhY3QnO1xuaW1wb3J0IHsgQXBwQ29uZmlnQ29udGV4dCB9IGZyb20gJy4uL2dsb2JhbC1jb250ZXh0LXdyYXBwZXIvZ2xvYmFsLWNvbnRleHQtd3JhcHBlcic7XG5pbXBvcnQgeyBjb250YWluZXJTdHlsZXMsIHdoaXRlVGhlbWUgfSBmcm9tICcuL2xvZ2luLW5hdmJhci1jdGEubW9kdWxlLnN0eWxlJztcblxuLyogZXNsaW50LWRpc2FibGUtbmV4dC1saW5lICovXG5leHBvcnQgaW50ZXJmYWNlIExvZ2luTmF2YmFyQ3RhUHJvcHMge1xuICB0aGVtZT86IFwid2hpdGVcIlxufVxuXG5leHBvcnQgZnVuY3Rpb24gTG9naW5OYXZiYXJDdGEocHJvcHM6IExvZ2luTmF2YmFyQ3RhUHJvcHMpIHtcbiAgY29uc3QgeyBzZXRMb2dpblN0YXRlIH0gPSB1c2VDb250ZXh0KEFwcENvbmZpZ0NvbnRleHQpO1xuICByZXR1cm4gKFxuICAgIDxkaXZcbiAgICAgIGNzcz17W2NvbnRhaW5lclN0eWxlcywgcHJvcHMudGhlbWUgPyB3aGl0ZVRoZW1lIDogbnVsbF19XG4gICAgICBvbkNsaWNrPXsoKSA9PiBzZXRMb2dpblN0YXRlKHsgb3BlbjogdHJ1ZSwgY3VycmVudFZpZXc6ICdsb2dpbicgfSl9XG4gICAgPlxuICAgICAgTG9naW5cbiAgICA8L2Rpdj5cbiAgKTtcbn1cblxuZXhwb3J0IGRlZmF1bHQgTG9naW5OYXZiYXJDdGE7XG4iXX0= */"],
    onClick: function onClick() {
      return setLoginState({
        open: true,
        currentView: 'login'
      });
    },
    children: "Login"
  });
}
export default LoginNavbarCta;