import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

/** @jsxImportSource @emotion/react */
import { iconStyles, addedNotificationContainer, actionNotificationProductImage } from './navbar-wishlist.module.style';
import { useContext } from 'react';
import { AppConfigContext } from '../global-context-wrapper/global-context-wrapper';
import { Typography } from '@vc-workspace/utils-typography';
import { getDisplayImageObj } from '@vc-workspace/utils-helpers';
import { IndievibeAmplitudeEventNames, sendAmplitudeData } from '@vc-workspace/utils-indievibe-amplitude';
/* eslint-disable-next-line */

export function NavbarWishlist(props) {
  var _useContext = useContext(AppConfigContext),
      itemAddedToWishlist = _useContext.itemAddedToWishlist,
      setItemAddedToWishlist = _useContext.setItemAddedToWishlist,
      isLoggedIn = _useContext.isLoggedIn,
      setSelectedTab = _useContext.setSelectedTab;

  function onWishlistClick() {
    sendAmplitudeData(IndievibeAmplitudeEventNames.GO_TO_WISHLIST, {
      source: 'Navbar',
      is_b2b_platform: true
    });
    window.location.href = '/profile/wishlist';
  }

  function onNotificationClick(ev) {
    ev.stopPropagation();
    setItemAddedToWishlist(null);
  }

  var productImage = {
    url: '',
    webP: ''
  };

  if (itemAddedToWishlist) {
    productImage = getDisplayImageObj(itemAddedToWishlist.itemDetail.images[0], '1');
  }

  return _jsxs("div", {
    onClick: onWishlistClick,
    className: "inline-block relative cursor-pointer mr-4",
    children: [props.whiteTheme ? _jsx("img", {
      src: "/_mp-images/heart_white.svg",
      css: iconStyles
    }) : _jsx("img", {
      src: "/_mp-images/heart_black.svg",
      css: iconStyles
    }), itemAddedToWishlist && !props.context.isMobileView ? _jsxs("div", {
      css: addedNotificationContainer,
      onClick: onNotificationClick,
      children: [_jsxs("picture", {
        children: [_jsx("source", {
          srcSet: productImage.webP,
          type: "image/webp"
        }), _jsx("img", {
          src: productImage.url,
          css: actionNotificationProductImage
        })]
      }), _jsx(Typography, {
        type: "Body1",
        color: "white",
        className: "text-center",
        semi_bold: true,
        children: "Added to wishlist"
      })]
    }) : null]
  });
}
export default NavbarWishlist;