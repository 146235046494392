
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { CountriesSchema } from '@vc-workspace/utils-schemas';
import { Colors } from '@vc-workspace/utils-colors';

interface CountrySelectorProps {
  country: string;
  setCountry: (c: string) => void;
  countriesData: CountriesSchema[];
  preSelectedCountry?: string 
}

export function CountrySelector(props: CountrySelectorProps) {
  return (
    <Autocomplete
      id="country-select"
      fullWidth
      options={props.countriesData}
      autoHighlight
      defaultValue={props.preSelectedCountry ? props.countriesData.filter(item => item.name.toLowerCase() === props.preSelectedCountry?.toLowerCase())[0] : null}
      getOptionLabel={(option: CountriesSchema) => option.name}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.shortName.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.shortName.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.name}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Country/Region"
          inputProps={{
            ...params.inputProps,
          }}
          variant="standard"
          sx={{
            '& input': {
              fontSize: '14px',
              fontFamily: 'Manrope',
            },
            '& :before': {
              borderBottom: `1px solid ${Colors.dark_grey}`
            }
          }}
        />
      )}
      onChange={(e, value: CountriesSchema | null) => value && props.setCountry(value.name)}
    />
  );
}
