import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

/** @jsxImportSource @emotion/react */
import { useRouter } from 'next/router';
import Link from 'next/link';
import { Typography } from '@vc-workspace/utils-typography';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { getGlobalSearchSuggestions } from '@vc-workspace/data-adapter';
import { searchHeaderContainer, inputStyles, searchLinkIcon, navIconStyles } from './explore.module.style';
import { CategorySelectionSources, SearchSuggestionTypes, SEARCH_QUERY_KEY } from '@vc-workspace/utils-constants';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { IndievibeAmplitudeEventNames, sendAmplitudeData } from '@vc-workspace/utils-indievibe-amplitude';
import debounce from 'lodash.debounce';
import { AppConfigContext, CategoryContext } from '../global-context-wrapper/global-context-wrapper';
import CategoryAccordian from '../category-accordian/category-accordian';
import { TailwindColors } from '@vc-workspace/utils-colors';
import { UtilsSeparator } from '@vc-workspace/utils-separator';
import { RetailEventTypes, trackGARetailEvent } from '@vc-workspace/utils-ga';
/* eslint-disable-next-line */

export function Explore(props) {
  var router = useRouter();
  var inputRef = useRef(null);

  var _useState = useState(''),
      searchInput = _useState[0],
      setSearchInput = _useState[1];

  var _useState2 = useState(null),
      searchSuggestions = _useState2[0],
      setSearchSuggestions = _useState2[1];

  var _useContext = useContext(AppConfigContext),
      userDetails = _useContext.userDetails;

  useEffect(function () {
    if (searchInput.length >= 3) {
      getGlobalSearchSuggestions(searchInput, router.query.v === "g" ? "g" : "m").then(function (res) {
        setSearchSuggestions(res);
      });
    } else {
      setSearchSuggestions(null);
    }
  }, [searchInput]);

  function onSearchInput(ev) {
    setSearchInput(ev.target.value);
  }

  var debouncedChangeHandler = useMemo(function () {
    return debounce(onSearchInput, 300);
  }, []);

  function onEnterPress(ev) {
    if (ev.key === 'Enter' && searchInput) {
      sendAmplitudeData(IndievibeAmplitudeEventNames.SEARCH_SUGGESTION_INTERACTED, {
        search_keyword: searchInput,
        suggestion_type: SearchSuggestionTypes.KEYWORD,
        is_b2b_platform: true
      });
      router.push("/search?".concat(SEARCH_QUERY_KEY, "=").concat(searchInput, "&v=").concat(router.query.v));
      onSearchSuggestionClick(0);
    }
  }

  function onSearchSuggestionClick(position, searchSuggestion) {
    if (inputRef && inputRef.current) {
      inputRef.current.value = '';
    }

    sendAmplitudeData(IndievibeAmplitudeEventNames.SEARCH_SUGGESTION_INTERACTED, {
      search_keyword: searchInput,
      position: position,
      is_b2b_platform: true
    });
    var payload = {
      eventType: RetailEventTypes.SEARCH,
      completionDetail: {
        completionAttributionToken: searchInput.toString().trim().toLowerCase(),
        selectedPosition: position,
        selectedSuggestion: searchSuggestion || searchInput
      },
      searchQuery: searchInput
    };

    if (userDetails && userDetails._id) {
      payload.userInfo = {
        userId: userDetails._id
      };
    }

    if (searchSuggestion && searchSuggestion.trim().split(" ").length <= 5 || !searchSuggestion) {
      trackGARetailEvent(payload);
    }
  }

  var onSearchFocus = function onSearchFocus() {
    sendAmplitudeData(IndievibeAmplitudeEventNames.SEARCH_CLICKED, {});
  };

  return _jsx(_Fragment, {
    children: _jsx(Dialog, {
      open: true,
      onClose: function onClose() {
        /* Do Nothing */
      },
      fullScreen: true,
      children: _jsxs("div", {
        className: "py-4 pb-14",
        children: [_jsx(Typography, {
          type: "SubHeading1",
          color: TailwindColors.grey[900],
          className: "pb-3 px-4",
          bold: true,
          children: "Explore"
        }), _jsxs("div", {
          css: searchHeaderContainer,
          className: "mx-4",
          children: [_jsx("img", {
            src: "/_mp-images/icons/search_black.png",
            css: navIconStyles
          }), _jsx("input", {
            type: "text",
            ref: inputRef,
            css: inputStyles,
            placeholder: "Search here",
            onChange: debouncedChangeHandler,
            onFocus: onSearchFocus,
            onKeyDown: onEnterPress
          }), searchInput ? _jsx(Typography, {
            type: "Body1",
            color: "night_rider",
            onClick: function onClick() {
              return setSearchInput('');
            },
            inline: true,
            children: _jsx(CloseIcon, {
              fontSize: "medium"
            })
          }) : null]
        }), searchSuggestions ? _jsxs(_Fragment, {
          children: [searchSuggestions.suggestions.length ? _jsx(Typography, {
            type: "Body1",
            color: TailwindColors.grey[500],
            className: "px-4 pt-3",
            semi_bold: true,
            children: "Products"
          }) : null, searchSuggestions.suggestions.map(function (item, index) {
            return _jsxs(_Fragment, {
              children: [_jsx(Link, {
                href: "/search?".concat(SEARCH_QUERY_KEY, "=").concat(item, "&v=").concat(router.query.v),
                passHref: true,
                children: _jsxs("div", {
                  onClick: function onClick() {
                    return onSearchSuggestionClick(index + 1);
                  },
                  className: "pl-4 pr-8 my-3 relative",
                  children: [_jsx(Typography, {
                    type: "Body1",
                    color: TailwindColors.grey[900],
                    className: "truncate w-full pr-3",
                    inline: true,
                    children: item
                  }), _jsx(Typography, {
                    type: "Body1",
                    color: TailwindColors.grey[600],
                    custom_style: searchLinkIcon,
                    inline: true,
                    children: _jsx(ArrowForwardIcon, {
                      fontSize: "small"
                    })
                  })]
                })
              }, item), _jsx(UtilsSeparator, {
                color: TailwindColors.grey[100]
              })]
            });
          })]
        }) : _jsx("div", {
          className: "px-4",
          children: _jsx(CategoryContext.Consumer, {
            children: function children(_ref) {
              var categories = _ref.categories,
                  curatedCategories = _ref.curatedCategories;
              return _jsx(CategoryAccordian, {
                categories: categories,
                curatedCategories: curatedCategories,
                source: CategorySelectionSources.EXPLORE
              });
            }
          })
        })]
      })
    })
  });
}
export default Explore;