import axios from "axios";
export function fetchGlobalCuratedCategories() {
  return axios({
    method: 'get',
    url: "".concat(process.env.NEXT_PUBLIC_API_PREFIX, "/v1/b2b/mall/categories/curated")
  }).then(function (res) {
    return res.data.curatedCategories || [];
  })["catch"](function () {
    return [];
  });
}
export function fetchAllGlobalCategories() {
  return axios({
    method: 'get',
    url: "".concat(process.env.NEXT_PUBLIC_API_PREFIX, "/v1/market/mall/categories")
  }).then(function (res) {
    if (!res.data.categories) {
      return [];
    }

    return res.data.categories.filter(function (item) {
      return item.hasMinProduct;
    });
  });
}