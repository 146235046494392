import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

/** @jsxImportSource @emotion/react */
import axios from 'axios';
import { Typography } from '@vc-workspace/utils-typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { UtilsSeparator } from '@vc-workspace/utils-separator';
import { DIMENSIONS } from '@vc-workspace/utils-dimens';
import { useContext, useRef, useState } from 'react';
import { removeKeyFromStorage } from '@vc-workspace/utils-helpers';
import { MARKETPLACE_ACCESS_TOKEN } from '@vc-workspace/utils-constants';
import { menuItemStyles, iconStyles } from './navbar-profile.module.style';
import { AppConfigContext } from '../global-context-wrapper/global-context-wrapper';
/* eslint-disable-next-line */

export function NavbarProfile(props) {
  var anchorEle = useRef(null);

  var _useState = useState(false),
      modalOpen = _useState[0],
      toggleModal = _useState[1];

  var _useContext = useContext(AppConfigContext),
      isLoggedIn = _useContext.isLoggedIn;

  function onLogout() {
    toggleModal(false);
    removeKeyFromStorage(MARKETPLACE_ACCESS_TOKEN);
    delete axios.defaults.headers.common[MARKETPLACE_ACCESS_TOKEN];
    window.location.reload();
  }

  function onMyOrdersClick() {
    toggleModal(false);
    window.location.href = '/profile/orders';
  }

  function onMyWishlistClick() {
    toggleModal(false);
    window.location.href = '/profile/wishlist';
  }

  return _jsxs(_Fragment, {
    children: [_jsx("div", {
      ref: anchorEle,
      className: "inline-block cursor-pointer ",
      onClick: function onClick() {
        return toggleModal(true);
      },
      children: props.whiteTheme ? _jsx("img", {
        src: "/_mp-images/profile_white.svg",
        css: iconStyles
      }) : _jsx("img", {
        src: "/_mp-images/profile.svg",
        css: iconStyles
      })
    }), _jsxs(Menu, {
      id: "profile-menu",
      anchorEl: anchorEle.current,
      open: modalOpen,
      onClose: function onClose() {
        return toggleModal(false);
      },
      className: "mt-2",
      MenuListProps: {
        'aria-labelledby': 'basic-button'
      },
      disableAutoFocusItem: true,
      children: [_jsx(MenuItem, {
        children: _jsx(Typography, {
          type: "Body1",
          color: "dim_grey",
          custom_style: menuItemStyles,
          onClick: onMyOrdersClick,
          children: "My Orders"
        })
      }), _jsx(MenuItem, {
        children: _jsx(Typography, {
          type: "Body1",
          color: "dim_grey",
          custom_style: menuItemStyles,
          onClick: onMyWishlistClick,
          children: "My Wishlist"
        })
      }), isLoggedIn ? _jsxs(_Fragment, {
        children: [_jsx(UtilsSeparator, {
          spacingTop: DIMENSIONS.px_4,
          spacingBottom: DIMENSIONS.px_4,
          color: "gainsboro"
        }), _jsx(MenuItem, {
          children: _jsx(Typography, {
            type: "Body1",
            color: "dim_grey",
            custom_style: menuItemStyles,
            onClick: onLogout,
            children: "Logout"
          })
        })]
      }) : null]
    })]
  });
}
export default NavbarProfile;