import { jsx as _jsx } from "@emotion/react/jsx-runtime";

/** @jsxImportSource @emotion/react */
import { TailwindColors } from '@vc-workspace/utils-colors';
import { Typography } from '@vc-workspace/utils-typography';
import { useContext } from 'react';
import { AppConfigContext } from '../global-context-wrapper/global-context-wrapper';
import { containerStyles } from './signup-navbar-cta.module.style';
/* eslint-disable-next-line */

export function SignupNavbarCta(props) {
  var _useContext = useContext(AppConfigContext),
      setLoginState = _useContext.setLoginState;

  return _jsx("div", {
    css: containerStyles,
    onClick: function onClick() {
      return setLoginState({
        open: true,
        currentView: 'signup'
      });
    },
    children: _jsx(Typography, {
      type: "Body1",
      color: TailwindColors.white,
      children: "Sign Up to Shop"
    })
  });
}
export default SignupNavbarCta;