import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

/** @jsxImportSource @emotion/react */
import { DOLLAR_CURRENCY_ID } from '@vc-workspace/utils-constants';
import { getDisplayImageObj } from '@vc-workspace/utils-helpers';
import { UtilsSeparator } from '@vc-workspace/utils-separator';
import { Typography } from '@vc-workspace/utils-typography';
import Link from 'next/link';
import { containerStyles, imageStyles } from './guest-wishlist-sia-card.module.style';
export function GUestWishlistSIACard(props) {
  var displayImage = getDisplayImageObj(props.sia.itemDetail.images[0], '2');
  var displayPrice = props.sia.meta.bestB2bPrice[DOLLAR_CURRENCY_ID];
  var discountAvailable = 0;
  var visibleMrp = 0;

  if (typeof displayPrice.mrp !== 'undefined') {
    visibleMrp = displayPrice.mrp;
  }

  if (displayPrice.discountPercent) {
    discountAvailable = displayPrice.discountPercent;
  }

  return _jsx(Link, {
    href: "/".concat(props.sia.itemDetail.slug, "/").concat(props.sia.itemDetail.usId),
    children: _jsxs("div", {
      css: containerStyles,
      children: [_jsxs("picture", {
        children: [_jsx("source", {
          srcSet: displayImage.webP,
          type: "image/webp"
        }), _jsx("img", {
          src: displayImage.url,
          css: imageStyles
        })]
      }), _jsxs("div", {
        className: "p-2",
        children: [_jsx(Typography, {
          type: "Body1",
          color: "nero",
          className: "truncate w-full",
          semi_bold: true,
          children: props.sia.itemDetail.name
        }), _jsx(Typography, {
          type: "Body2",
          color: "dim_grey",
          className: "truncate pt-1",
          children: props.sia.shopName
        }), _jsxs("div", {
          children: [_jsxs(Typography, {
            type: 'SubHeading1',
            color: 'nero',
            semi_bold: true,
            inline: true,
            children: ["$", displayPrice.sellingPrice / 100]
          }), visibleMrp > 0 && visibleMrp !== displayPrice.sellingPrice ? _jsxs(Typography, {
            type: 'Body2',
            color: 'shady_lady',
            className: "px-1 line-through",
            inline: true,
            children: ["$", visibleMrp / 100]
          }) : null, discountAvailable ? _jsxs(Typography, {
            type: 'Body2',
            color: 'dark_tangerine',
            inline: true,
            children: ["(", discountAvailable, "% OFF)"]
          }) : null]
        })]
      }), _jsx(UtilsSeparator, {
        color: "gainsboro",
        spacingTop: "0px",
        spacingBottom: "0px"
      }), _jsx("div", {
        className: "p-2",
        children: _jsx(Typography, {
          type: "Body1",
          color: "navy",
          className: "text-center",
          semi_bold: true,
          children: "Move to Bag"
        })
      })]
    })
  });
}
export default GUestWishlistSIACard;