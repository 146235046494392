
export interface IndievibeLogoProps {
  theme: string,
  disable_redirect?: boolean,
  width: string,
  without_tagline?: boolean
}

export const INDIEVIBE_LOGO_THEMES = {
  DEFAULT: 'DEFAULT',
  WHITE: 'WHITE',
};


export function IndievibeLogo(props: IndievibeLogoProps) {

  let logo_url = 'https://vibecity-provider-media-production.s3.ap-south-1.amazonaws.com/logo/indievibe_royalblue.png';

  switch (props.theme) {
    case INDIEVIBE_LOGO_THEMES.WHITE:
      logo_url = 'https://vibecity-provider-media-production.s3.ap-south-1.amazonaws.com/logo/indievibe_white.png';
      break;

    // no default
  }

  function handleClick (ev: any) {
    if (props.disable_redirect) {
      ev.preventDefault();
      return false;
    }
    return true
  };

  return (
    <a href="/" onClick={handleClick} className="inline-block">
      <img
        src={logo_url}
        width={props.width} alt="Indievibe" />
    </a>
  );
}


